// src/resourcesData.js

import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';

const resources = [
    {
        title: "Interactive Math Tutorials",
        imageUrl: image1
    },
    {
        title: "Science Experiments for Kids",
        imageUrl: image2
    },
    {
        title: "English Stories for Kids",
        imageUrl: image3
    },
    // Add more resources as needed
];

export default resources;
