import React from 'react'
import Main from '../components/Main'
import SlidingImageAnimation from '../components/SlidingImagesAnimation'
import BeautifulTextWithMessage from "../components/BeautifulTextWithMessage";
import DynamicTextAnimation from "../components/DynamicTextAnimation";
import EducationalResource from "../components/EducationalResource";
import resources from '../components/resourcesData';

const Home = () => {
    return (
        <>
            <Main />
            <SlidingImageAnimation/>
            <div className="container mx-auto p-6">
      <DynamicTextAnimation
        message="Welcome to Your Learning Adventure!"
      />
    </div>
      <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center text-red-600">Featured Resources</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {resources.map((resource, index) => (
          <EducationalResource
            key={index}
            title={resource.title}
            imageUrl={resource.imageUrl}
          />
        ))}
      </div>
    </div>
    <div className="container mx-auto p-6">
      <BeautifulTextWithMessage
        title="Welcome to Our Learning Hub"
        message="Discover a world of educational resources designed to enhance your knowledge and inspire a love for learning. Explore our interactive tools and engaging content tailored just for you."
      />
    </div>
        </>
    )
}

export default Home